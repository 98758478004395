<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader header-text="Install Automated Export plugin">
      <template #subheader>
        Please configure the following in order to install the Automated Export plugin.
      </template>
    </PageHeader>

    <v-alert
      v-if="selectedArchivedSegment"
      type="warning"
      class="mt-8"
    >
      This segment is archived which will prevent the export from being created.
      Please fix this configuration by configuring a different segment.
    </v-alert>

    <AutomatedExportConfigurations
      :automated-export-configurations="exportConfigurations"
      :export-type-options="exportTypeOptions"
      :contact-export-choice-options="contactExportChoiceOptions"
      :segment-options="segmentOptions"
      :action-export-contact-choice-options="actionExportContactChoiceOptions"
      :action-export-code-choice-options="actionExportCodeChoiceOptions"
      :action-code-options="actionCodeOptions"
      @add-configuration="type => addConfiguration({type}, true)"
      @clone-configuration="cloneConfiguration"
      @delete-configuration="deleteConfig"
      @update-configuration="updateValue"
    />

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          v-if="installed"
          class="custom-button custom-button--red-text px-8 mr-4"
          height="34px"
          depressed
          @click="deactivateDialog = true;"
        >
          Deactivate
        </v-btn>

        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="exportConfigurations.length <= 0"
          @click="installPlugin"
        >
          {{ installed ? "Configure" : "Install" }}
        </v-btn>
      </v-col>
    </v-row>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import cloneDeep from "lodash/cloneDeep";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";
import importExportMixin from "@/utils/import-export-mixin";
import AutomatedExportConfigurations from "@/views/Plugins/components/AutomatedExportConfigurations.vue";

export default {
  name: "AddPluginAutomatedExport",
  metaInfo: {
    title: 'Install Automated Export'
  },
  components: {
    PageHeader,
    DeactivateConfirmDialog,
    AutomatedExportConfigurations,
  },
  mixins: [importExportMixin],
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    exportTypeOptions: [
      {
        id: 'contact',
        text: 'Contact Export',
      },
      {
        id: 'alert_action',
        text: 'Alert Export',
      },
      {
        id: 'email_action',
        text: 'Email Action Export',
      },
      {
        id: 'banner_action',
        text: 'Banner Action Export',
      },
      {
        id: 'event_action',
        text: 'Event Action Export',
      },
      {
        id: 'direct_mail_action',
        text: 'Direct Mail Action Export',
      },
      {
        id: 'phone_action',
        text: 'Phone Action Export',
      },
      {
        id: 'prescription_action',
        text: 'Prescription Action Export',
      },
      {
        id: 'form_action',
        text: 'Form Action Export',
      },
      {
        id: 'web_action',
        text: 'Web Action Export',
      },
      {
        id: 'email_subscription_status',
        text: 'Email Subscription Status Report'
      },
    ],
    contactExportChoiceOptions: [
      {
        id: 'all',
        text: 'All Contacts',
      },
      {
        id: 'segment',
        text: 'Specific Segment',
      },
    ],
    segmentOptions: [],
    actionExportContactChoiceOptions: [
      {
        id: 'all',
        text: 'All Contacts',
      },
      {
        id: 'segment',
        text: 'Specific Segment',
      },
    ],
    actionExportCodeChoiceOptions: [
      {
        id: 'all',
        text: 'All Actions',
      },
      {
        id: 'specific_action_codes',
        text: 'Specific Action Codes',
      },
    ],
    actionCodeOptions: [],
    plugin: null,
    selectedArchivedSegment: false,
    loadSegmentCollection: []
  }),
  async created() {
    await this.loadActionCodes();
    if (this.installed) {
      await this.loadAccountPlugin();
    }

    await this.loadSegment();
  },
  methods: {
    async loadAccountPlugin() {
      const resp = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!resp.data || !resp.data.pluginConfiguration?.configurations) {
        return;
      }

      this.plugin = {...resp.data};

      const credentials = resp.data.pluginConfiguration?.credentials;
      const configurations = resp.data.pluginConfiguration?.configurations.map(config => {
        const configCredentials = credentials[config.key] ?? null;

        if (config?.frequency?.time) {
          const [hour, minute] = config.frequency.time.split(":");
          if (+hour >= 12) {
            config.frequency.ampm = "PM"
            config.frequency.hour = +hour > 12 ? +hour - 12 : 12;
          }
          else {
            config.frequency.ampm = "AM"
            config.frequency.hour = (+hour === 0) ? 12 : hour;
          }
          config.frequency.minute = minute;

          // Remove this config in case the frequency changes
          delete config.frequency.nextRunDate;
        }

        return {
          ...config,
          credentials: configCredentials,
        };
      });

      configurations.forEach(config => {
        this.addConfiguration(config);
      });
    },
    async loadActionCodes() {
      const resp = await this.$rest.action_codes.getCollection({
        fields: ['id', 'name', 'tacticId'],
        ignorePagination: true,
      });
      if (!resp.data) {
        return;
      }

      this.actionCodeOptions = resp.data;
    },
    async loadSegment() {
      const response = await this.$rest.segment.get_collection({
        ignorePagination: true,
        sort: ['name:asc'],
      });

      if (!response.data) {
        return;
      }

      this.loadSegmentCollection = response.data.items;
      response.data.items.forEach(item => {
        this.exportConfigurations.forEach(itemConf => {
          if (itemConf.exportOptions
              && itemConf.exportOptions.segmentId
              && item.id === itemConf.exportOptions.segmentId
              && item.isArchived) {
            this.selectedArchivedSegment = true;
          }
        })
        this.segmentOptions.push({
          id: item.id,
          text: item.isArchived ? item.name + ' [Archived]': item.name,
          type: item.type,
          disabled: item.isArchived
        });
      });
    },
    addConfiguration(existingConfig, isShowing = null) {
      this.addExportConfiguration(this.generateExportConfiguration(existingConfig, isShowing));
    },
    cloneConfiguration(index) {
      const existingConfig = this.exportConfigurations[index] ?? null;

      this.addConfiguration(
        {
          ...existingConfig,
          name: 'Clone of ' + existingConfig?.name || '',
          key: null,
          history: null,
          lastExportDate: null,
        },
        0 // 0 means it will be open
      );

      this.$set(this.exportConfigurations[index], 'isShowing', null);
    },
    async deleteConfig(deleteId) {
      if (!this.installed) {
        this.exportConfigurations.splice(deleteId, 1);

        return;
      }

      const pluginConfiguration = {
        configurations: [],
        credentials: {},
      };

      let modifiedConfigurations = this.exportConfigurations.slice();
      modifiedConfigurations.splice(deleteId, 1);
      for (const config of modifiedConfigurations) {
        const { credentials, ...configuration } = cloneDeep(config);

        if (configuration.frequency.ampm) {
          const hour =
            configuration.frequency.ampm === 'PM' && configuration.frequency.hour < 12
              ? configuration.frequency.hour + 12
              : (
                configuration.frequency.ampm === 'AM' && configuration.frequency.hour === 12
                  ? 0
                  : configuration.frequency.hour
              );
          configuration.frequency.time = `${hour.toString().padStart(2, '0')}:${
            configuration.frequency.minute
          }:00`;
        }

        // remove extra properties
        delete configuration.frequency.hour;
        delete configuration.frequency.minute;
        delete configuration.frequency.ampm;

        if (configuration.frequency.type === 'daily') {
          delete configuration.frequency.day;
        }

        if (configuration.connectionType === 'FTP'
          || configuration.connectionType === 'FTPS'
          || configuration.connectionType === 'SFTP'
        ) {
          delete credentials.privateKey;
          delete credentials.aws_region;
          delete credentials.aws_access_id;
          delete credentials.aws_secret_key;
          delete credentials.aws_bucket;
        } else if (configuration.connectionType === 'SFTP_WITH_KEY') {
          delete credentials.password;
          delete credentials.aws_region;
          delete credentials.aws_access_id;
          delete credentials.aws_secret_key;
          delete credentials.aws_bucket;
        } else if (configuration.connectionType === 'AMAZON_S3') {
          delete credentials.username;
          delete credentials.password;
          delete credentials.port;
          delete credentials.host;
          delete credentials.privateKey;
        }

        delete configuration.isShowing;
        pluginConfiguration.configurations.push(configuration);
        pluginConfiguration.credentials[configuration.key] = credentials;
      }

      await this.$rest.plugins
        .put_resource(this.id, {
          isActive: true,
          pluginConfiguration,
        })
        .then(() => {
          this.exportConfigurations.splice(deleteId, 1);
          this.$store.commit('snackbar/showMessage', {
            content: 'Configuration deleted',
            color: 'success',
          });
        })
        .catch(() => {
          this.$store.commit('snackbar/showMessage', {
            content: 'Failed when trying to configure plugin',
            color: 'error',
          });
        });
    },
    updateValue(index, key, value) {
      this.updateExportValue(index, key, value);

      if (key === 'exportOptions.segmentId') {
        this.selectedArchivedSegment = false;
        this.loadSegmentCollection.forEach(item => {
          this.exportConfigurations.forEach(itemConf => {
            if (itemConf.exportOptions
                && itemConf.exportOptions.segmentId
                && item.id === itemConf.exportOptions.segmentId
                && item.isArchived
            ) {
              this.selectedArchivedSegment = true;
            }
          })
        })
      }
    },
    async installPlugin() {
      const pluginConfiguration = {
        configurations: [],
        credentials: {},
      };

      for (const config of this.exportConfigurations) {
        const result = this.formatConfig(config);
        pluginConfiguration.credentials[result.configuration.key] = result.credentials;
        pluginConfiguration.configurations.push(result.configuration);
      }

      let response = null;
      if (this.installed) {
        response = await this.$rest.plugins
          .put_resource(this.id, {
            isActive: true,
            pluginConfiguration,
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to configure plugin',
              color: 'error',
            });
          });
      } else {
        response = await this.$rest.plugins
          .post_resource({
            plugin: +this.id,
            isActive: true,
            pluginConfiguration,
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }
            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to install plugin',
              color: 'error',
            });
          });
      }

      if (!response?.data) {
        return;
      }

      this.$store.commit('snackbar/showMessage', {
        content: this.installed
          ? 'Plugin configured successfully'
          : 'Plugin installed successfully',
        color: 'success',
      });

      await this.$router.push({ name: 'Plugins' });
    }
  },
};
</script>
